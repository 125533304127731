import { Component, OnInit, OnDestroy, HostListener, Renderer2, ComponentFactoryResolver } from '@angular/core';
import { Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import {
  latLng, Layer, tileLayer, Map, geoJSON, CircleMarker
} from 'leaflet';
import { GeoJsonObject } from 'geojson';
import { CityClockComponent } from '../city-clock/city-clock.component';
import { Observable, of, timer } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { ZoneService } from '../services/zone.service';
import { Zone, ZDict } from '../interfaces';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { TooltipClockComponent } from '../tooltip-clock/tooltip-clock.component';
import { MarkerCityClockComponent } from '../marker-city-clock/marker-city-clock.component';

import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-world-map',
  templateUrl: './world-map.component.html',
  styleUrls: ['./world-map.component.scss']
})
export class WorldMapComponent implements OnInit, OnDestroy {
  title = 'app';
  zonesForm: FormControl = new FormControl();
  zonesDict: ZDict = {};
  filteredZones: Observable<ZDict>;
  data: GeoJsonObject;
  geo: any;
  options = {};
  newLayer: Layer;
  map: Map;
  countryName: string;
  cityName: string;
  countryCityLink: string;
  initialZone: Zone;
  selectedZone: Zone;
  selectedTooltipZone: Zone;
  routeSub$: Subscription;
  features: any;
  isDataLoaded = false;
  factoryCityClock: any;
  factoryTooltipClock: any;
  markers: {} = {};
  public innerHeight: any = 1000;
  markTimer = timer(0, 501);

  @ViewChild('myMap', { static: true }) mapContainer;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
  }

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    private zoneService: ZoneService,
    private titleService: Title,
    private meta: Meta,
    private renderer: Renderer2
  ) {

    // page info
    this.titleService.setTitle('Fuso Horário Mundial');
    this.meta.updateTag({
      name: 'description',
      content: 'Descubra a hora atual e a diferença de fusos horários entre todos os países do mundo através de um lindo mapa.'
    });

    // map otions
    this.options = {
      // TODO fix that
      // layers: [
      //   tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png?lang=pt', {
      //     maxZoom: 16,
      //     attribution: '...'
      //   })
      // ],
      zoom: 3,
      maxBoundsViscosity: 1.0,
      closePopupOnClick: false,
      center: latLng(0, 0),
      attributionControl: false,
      preferCanvas: true
    };
  }


  ngOnInit() {

    this.innerHeight = window.innerHeight;

    this.countryName = this.route.snapshot.paramMap.get('country_name');
    this.cityName = this.route.snapshot.paramMap.get('city_name');
    this.countryCityLink = '/c/' + this.countryName + '/' + this.cityName;

    // build zones dict
    this.zoneService.getZonesOrdered().then(res => {
      this.zonesDict = res['zoneList'];

      // detect initial zone based on route
      const initialZone = this.zonesDict[this.countryCityLink];
      if (initialZone !== undefined) {
        this.selectedZone = initialZone;
        let newLat = Number.parseFloat(this.selectedZone.lat) + 25;
        newLat = newLat > 90 ? 80 : newLat;
        this.options['center'] = latLng(newLat, this.selectedZone.lon);
        this.initialZone = this.selectedZone;
      }

      // create map with overwritten options in case of initial zone
      this.map = new Map(this.mapContainer.nativeElement, this.options);

      // control zoom
      this.map.zoomControl.setPosition('bottomright');

      // geojson
      this.data = <GeoJsonObject>res;
      this.geo = geoJSON(this.data);
      this.map.addLayer(this.geo);

      // create default zone markers
      for (const key of Object.keys(this.zonesDict)) {
        const zone: Zone = this.zonesDict[key];
        const mark = this.createMarker(zone, this.renderer, this.componentFactoryResolver);
        mark.addTo(this.map);
        this.markers[zone.link] = mark;
      }

      // attach routing rules
      this.routeSub$ = this.route.paramMap.pipe(
        switchMap((params: ParamMap) => {
          this.countryName = params.get('country_name');
          this.cityName = params.get('city_name');

          if (this.countryName !== undefined
            && this.countryName !== null
            && this.cityName !== undefined
            && this.cityName !== null) {
            this.countryCityLink = '/c/' + this.countryName + '/' + this.cityName;
            this.selectedZone = null;

            const tmpZone = this.zonesDict[this.countryCityLink];
            if (tmpZone !== undefined) {
              this.selectedZone = tmpZone;
            }

            return of(params.get('country_city_url'));
          }
          return of('');
        })
      ).subscribe(val => {
        if (this.initialZone !== this.selectedZone) {
          this.initialZone = null; // enables navigation
        }
        this.updateMap();
      });

      // attach filter rules drop down
      this.filteredZones = this.zonesForm.valueChanges.pipe(debounceTime(100)).pipe(
        startWith(''),
        map((value: string): ZDict => {
          const filterValue = ZoneService.normalizeString(value.toLowerCase());
          return Object.assign({}, ...
            Object.entries(this.zonesDict).filter(([k, v]) => v.sortName.includes(filterValue)).map(([k, v]) => ({ [k]: v }))
          );
        })
      );

    });
  }


  // Creates red circles into the UI that represent timezones
  private createMarker(zone: Zone, renderer: Renderer2, componentFactoryResolver): CircleMarker {
    let mark: MarkerCityClockComponent = new MarkerCityClockComponent(this.injector, zone, componentFactoryResolver);
    mark.selectZoneEvent.subscribe((zone: Zone) => {
      if (zone instanceof Zone) {
        this.selectZone(undefined, zone);
      }
    })
    return mark;
  }


  private updateMap() {
    if (!!this.map) {
      if (!!this.selectedZone && this.initialZone === null) {
        const newPoint = this.map.latLngToContainerPoint([this.selectedZone.lat, this.selectedZone.lon]);
        if (this.innerHeight < 800) {
          newPoint.y = newPoint.y - 320;
        } else {
          newPoint.y = newPoint.y - 280;
        }

        const newLat = this.map.containerPointToLatLng(newPoint);
        this.map.flyTo(newLat, this.map.getZoom(), { animate: true });
      }

      const mark: MarkerCityClockComponent = this.markers[this.selectedZone.link];
      mark.openPopup();

      this.updatePageTitleAndDescription();
    } else {
      this.router.navigate(['/']);
      this.map.panTo([-3.7900979, -38.5891583], { animate: true, duration: 1 });
    }
  }

  private selectZone(event: MatOptionSelectionChange, zone: Zone) {
    if (event === undefined || event.isUserInput) {
      this.router
        .navigate([zone.link])
        .then(success => {
          console.log('navigation ok');
        })
        .catch(err => {
          console.log(`navigation error ${err}`);
        });
    }
  }

  private updatePageTitleAndDescription() {
    if (this.selectedZone.cityNamePT === this.selectedZone.cityName) {
      this.titleService.setTitle('Fuso Horário em ' +
        this.selectedZone.cityNamePT +
        ' ' + Zone.conv(this.selectedZone.countryName) +
        ' ' + this.selectedZone.countryName);
    } else {
      this.titleService.setTitle('Fuso Horário em ' +
        this.selectedZone.cityNamePT + ' (' + this.selectedZone.cityName + ')' +
        ' ' + Zone.conv(this.selectedZone.countryName) +
        ' ' + this.selectedZone.countryName);
    }
    this.meta.updateTag({
      name: 'description',
      content: 'Hora atual em ' + this.selectedZone.cityNamePT +
        ' e a diferença de horário em relação ao Brasil e outros países.'
    });
  }


  selectAllContent($event) {
    $event.target.select();
  }

  ngOnDestroy() {
    this.routeSub$.unsubscribe()
  }
}
