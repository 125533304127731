export class Zone {
    countryCode: string;
    countryName: string;
    countryNameNormalized: string; // country name link style
    cityName: string;
    cityNamePT: string;
    displayName?: string;
    sortName?: string;
    link?: string;
    zone?: string;
    flag?: string;
    lat?: any;
    lon?: any;
    population?: any;
    offset?: string;
    offsetBrasil?: string;
    currencies?: Currency[];

    static conv(name: string) {
        if (name.endsWith('a')) {
            return 'na';
        } else if (name.endsWith('as')) {
            return 'nas';
        } else if (name.endsWith('os')) {
            return 'nos';
        } else if (name.endsWith('o') || name.endsWith('e') || name.endsWith('i') || name.endsWith('u')) {
            return 'no';
        } else {
            return 'no';
        }

        // Quênia e do Camboja sao masculinos, implementar correcao
    }

    constructor() {
    }
}

export interface Currency {
    name: string,
    symbol: symbol
}

export interface DateInfo {
    time: string;
    date: string;
    weekday: string;
    full: string;
}

export interface ZDict {
    [key: string]: Zone;
}

export interface Holiday {
    name: string;
    date: string;
}

export interface Country {
    countryName: string;
    countryCode: string;
}