const API = "https://s5qzpe1u3i.execute-api.sa-east-1.amazonaws.com/dev/api/holidays"
//const API = "http://localhost:3000/api/holidays"


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import 'moment-timezone';
import { Holiday } from '../interfaces';


@Injectable({
    providedIn: 'root'
})
export class HolidaysService {
    public static normalizeString(text: string) {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    static padLeft(text: string, padChar: string, size: number): string {
        return (String(padChar).repeat(size) + text).substr((size * -1), size);
    }

    constructor(private httpClient: HttpClient) {
        moment.locale('pt-BR');
    }

    getHolidaysByCountry(countryCode: string): Promise<Holiday[]> {
        const api = `${API}/?countryCode=${countryCode}`
        const source = this.httpClient.get<Object[]>(api).pipe(map(o => o.map((h): Holiday => ({
            name: h['name_pt'] ? h['name_pt'] : h['name'],
            date: moment(h['date'].substr(0,10)).format("DD/MM/yyyy")
        })))).toPromise();

        return source;
    }

}
