import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WhatIsTimeZoneComponent } from './what-is-time-zone.component';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-dialog-entry',
    template: ''
})
export class DialogEntryComponent {

    constructor(private dialog: MatDialog, private router: Router,
        private titleService: Title) {
        this.openDialog();
        this.titleService.setTitle('O que é fuso horário?');
    }

    openDialog() {
        const dialogRef = this.dialog.open(WhatIsTimeZoneComponent, {
            data: {},
            disableClose: false,
            hasBackdrop: true
        });
        dialogRef.afterClosed().subscribe(closed => {
            this.router.navigate(['/']);
        });
    }
}
