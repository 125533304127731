import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ZoneService } from '../services/zone.service';
import { HolidaysService } from '../services/holidays.service';
import { Zone, DateInfo, Holiday } from '../interfaces';

@Component({
  selector: 'app-city-clock',
  templateUrl: './city-clock.component.html',
  styleUrls: ['./city-clock.component.scss']
})

export class CityClockComponent implements OnInit {
  dateInfo: DateInfo = { time: '', date: '', full: '', weekday: '' };
  public zone: Zone;
  public holidays: any;

  constructor(public el: ElementRef, public zs: ZoneService, public hs: HolidaysService) {
  }

  ngOnInit() {
   
  }

  ngAfterViewInit() {
    this.hs.getHolidaysByCountry(this.zone.countryCode).then(data => {
      this.holidays = data
    }).catch(error => {
      console.log(error)
    })
  }

  public getHtml() {
    this.dateInfo = this.zs.getCurrentTimeNow(this.zone);
    return this.el.nativeElement.innerHTML;
  }
}
