import { Component, OnInit, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { ZoneService } from '../services/zone.service';
import { Zone, DateInfo } from '../interfaces';

@Component({
  selector: 'app-tooltip-clock',
  templateUrl: './tooltip-clock.component.html',
  styleUrls: ['./tooltip-clock.component.scss']
})
export class TooltipClockComponent implements OnInit, OnDestroy, AfterViewInit {
  dateInfo: DateInfo = { time: '', date: '', full: '', weekday: '' };
  public zone: Zone;

  constructor(private el: ElementRef, public zs: ZoneService) {} 

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  public getHtml() {
    this.dateInfo = this.zs.getCurrentTimeNow(this.zone);
    return this.el.nativeElement.innerHTML;
  }
}
