import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DialogEntryComponent } from './what-is-time-zone/dialog-entry.component';
import { WorldMapComponent } from './world-map/world-map.component';
import { HolidaysComponent } from './holidays/holidays.component';

const appRoutes: Routes = [
  { path: 'feriados/:country_name', component: HolidaysComponent },
  { path: 'c/:country_name/:city_name', component: WorldMapComponent },
  { path: 'tabela', loadChildren: () => import('./zone-table/zone-table.module').then(m => m.ZoneTableModule) }, 
  { path: 'o-que-e-fuso-horario', component: DialogEntryComponent },
  { path: '', component: WorldMapComponent }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
