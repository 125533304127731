import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import 'moment-timezone';
import { Zone, DateInfo, ZDict, Country } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  private data: any;
  private zoneDict: ZDict = {};
  private defaultTimer;

  public static normalizeString(text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  static padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
  }

  constructor(private httpClient: HttpClient) {
    this.defaultTimer = timer(0, 500).pipe(map(val => moment()));
    moment.locale('pt-BR');
  }

  getZones(): Observable<any> {
    const source = this.httpClient.get('assets/countries_new.geo.json');
    return source;
  }

  async getZonesOrdered(): Promise<{}> {
    this.data = await this.getZones().toPromise();

    for (const country of this.data['features']) {
      for (const z of country.properties.zones) {
        this.zoneDict[z.link] = <Zone>{
          countryCode: z.cca2,
          countryName: z.country_name_pt,
          countryNameNormalized: z.countryNameNormalized,
          cityName: z.city_name,
          cityNamePT: z.city_name_pt,
          displayName: z.display_name,
          sortName: z.sort_name,
          link: z.link,
          zone: z.zone,
          flag: z.flag,
          lat: z.lat,
          lon: z.lon,
          population: z.population,
          offSet: z.offset,
          offsetBrasil: z.offsetBrasil,
          currencies: z.currencies
        }
      }
    }

    return {
      ...this.data,
      zoneList: this.zoneDict
    }
  }


  getCurrentTime(zone: Zone): Observable<DateInfo> {
    const observable = Observable.create(observer => {
      this.defaultTimer.subscribe(m => {
        const dateInfo: DateInfo = this.getCurrentTimeNow(zone);
        observer.next(dateInfo);
      });
    });
    return observable;
  }

  getCurrentTimeNow(zone: Zone): DateInfo {
    const tz = moment().tz(zone.zone);
    const dateInfo: DateInfo = {
      time: tz.format('HH:mm:ss').toString(),
      date: tz.format('DD/MM').toString(),
      weekday: tz.format('dddd').toString(),
      full: tz.format('HH:mm:ss, dddd DD/MM').toString()
    };
    return dateInfo;
  }

  async getCountry(countryNameNormalized: string): Promise<Country> {
    this.data = await this.getZonesOrdered();
    for (const zone of Object.values<Zone>(this.data.zoneList)) {
      if (zone.countryNameNormalized === countryNameNormalized) {
        return <Country>{
          countryName: zone.countryName,
          countryCode: zone.countryCode
        }
      }
    }
    throw ("Country not found")
  }
}
