import { Component, OnInit, ComponentFactory, EventEmitter, Output } from '@angular/core';
import {
  latLng, CircleMarker
} from 'leaflet';

import { Zone } from '../interfaces';
import { CityClockComponent } from '../city-clock/city-clock.component';
import { TooltipClockComponent } from '../tooltip-clock/tooltip-clock.component';
import { ComponentFactoryResolver } from '@angular/core';
import { Injector } from '@angular/core';
import { timer } from 'rxjs';
import { ComponentRef } from '@angular/core';


@Component({
  selector: 'app-marker-city-clock',
  templateUrl: './marker-city-clock.component.html',
  styleUrls: ['./marker-city-clock.component.css']
})
export class MarkerCityClockComponent extends CircleMarker implements OnInit {

  clockCompRef: ComponentRef<CityClockComponent>;
  tooltipCompRef: ComponentRef<TooltipClockComponent>;
  timer: any;
  popupTimer: any;
  tooltipTimer: any;
  @Output() selectZoneEvent = new EventEmitter<Zone>();


  constructor(
    public injector: Injector,
    private zone: Zone,
    public componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(latLng(zone.lat, zone.lon), { radius: 5, color: 'red' })
    this.on('click', val => {
      this.selectZoneEvent.emit(this.zone);
    })

    const factoryCityClock = this.componentFactoryResolver.resolveComponentFactory(CityClockComponent);
    const factoryTooltipClock = this.componentFactoryResolver.resolveComponentFactory(TooltipClockComponent);

    this.clockCompRef = factoryCityClock.create(this.injector);
    this.clockCompRef.instance.zone = zone;

    this.tooltipCompRef = factoryTooltipClock.create(this.injector);
    this.tooltipCompRef.instance.zone = zone;

    this.timer = timer(0, 250)

    this.bindPopup(() => {
      return this.clockCompRef.instance.getHtml();
    }, { autoPan: false });

    this.getPopup().on('add', () => {
      this.popupTimer = this.timer.subscribe(() => {
        this.clockCompRef.hostView.detectChanges();
        this.setPopupContent(this.clockCompRef.instance.getHtml());
      });
    })

    this.getPopup().on('remove', () => {
      this.popupTimer.unsubscribe();
    });

    this.bindTooltip(() => {
      return this.tooltipCompRef.instance.getHtml();
    });

    this.getTooltip().on('add', () => {
      this.tooltipTimer = this.timer.subscribe(() => {
        this.tooltipCompRef.hostView.detectChanges();
        this.setTooltipContent(this.tooltipCompRef.instance.getHtml());
      });
    })

    this.getTooltip().on('remove', () => {
      this.tooltipTimer.unsubscribe()
    })
  }

  ngOnInit(): void {
    // do nothing
  }

}
