import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Holiday } from '../interfaces';
import { HolidaysService } from '../services/holidays.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css']
})
export class HolidaysComponent implements OnInit {

  sortedData: Holiday[] = [];
  holidays: Holiday[] = []
  countryName: string

  constructor(private route: ActivatedRoute, public hs: HolidaysService, public zs: ZoneService, 
    private titleService: Title) {

    const countryNameNormalized = this.route.snapshot.paramMap.get('country_name');

    this.zs.getCountry(countryNameNormalized).then((country) => {
      this.hs.getHolidaysByCountry(country.countryCode).then(data => {
        this.holidays = data;
        this.sortedData = this.holidays.slice();
        this.countryName = country.countryName;
        this.titleService.setTitle(`Feriados ${this.countryName}`);
      })
    });
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortedData = this.holidays.slice();
      return;
    }
  }

  ngOnInit(): void {
  }

}
